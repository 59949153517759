@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
body {
  margin: 0;
  font-family: 'Helvetica',YuGothic,'Yu Gothic',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,body,#root {
  height: 100vh;
}

*,
*::before,
*::after {
  margin-top: 0;
  box-sizing: border-box;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.wrapper {
  position: relative;
  max-width: 1000px;
  height: 100vh;
  margin: 0 auto;
  padding: 0 8px 0;
}

h1,h2,h3,h4,h5,p,a {
  color: black;
}

a {
  text-decoration: none;
}

a:hover {
  background-color: none;
}


/* ?????????????????�? */
.container {
  margin: 180px 0;
  padding: 0 16px 0;
}

@media screen and (max-width: 767px) {
.container {
  margin: 130px 0;
}
}

::-webkit-scrollbar {
  display: none;
}

/*
======================
burger menu
======================
*/

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  transition: .2s all;
  -webkit-tap-highlight-color: transparent; }
  .burger .burger-lines:after {
    left: 0;
    top: -1em; }
  .burger .burger-lines:before {
    left: 1em;
    top: 1em; }
  .burger:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%; }
  .burger .burger-lines {
    top: 50%;
    margin-top: -0.125em; }
    .burger .burger-lines, .burger .burger-lines:after, .burger .burger-lines:before {
      pointer-events: none;
      display: block;
      content: '';
      width: 100%;
      border-radius: 0.25em;
      background-color: #28385E;
      height: 0.25em;
      position: absolute;
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    .burger .burger-lines:after {
      left: 0;
      top: -1em; }
    .burger .burger-lines:before {
      left: 1em;
      top: 1em; }
.burger.burger-arrow .burger-lines, .burger.burger-arrow .burger-lines:after, .burger.burger-arrow .burger-lines:before {
  transition: .2s top, .2s left, .2s transform, .4s background-color .2s; }

.burger.burger-arrow .burger-lines:after, .burger.burger-arrow .burger-lines:before {
  width: 2em; }

.burger.burger-arrow.open.burger-down {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.burger.burger-arrow.open.burger-up {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.burger.burger-arrow.burger-right {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .burger.burger-arrow.burger-right .burger-lines:after {
    left: 1em;
    top: -1em; }
  .burger.burger-arrow.burger-right .burger-lines:before {
    left: 0em;
    top: 1em; }

.burger.burger-arrow.open .burger-lines, .burger.burger-arrow.open .burger-lines:after, .burger.burger-arrow.open .burger-lines:before {
  transition: .2s background-color, .2s top, .2s left, .2s transform; }

.burger.burger-arrow.open .burger-lines {
  background-color: #28385E; }

.burger.burger-arrow.open .burger-lines:before, .burger.burger-arrow.open .burger-lines:after {
  left: -.3em;
  top: 0px; }

.burger.burger-arrow.open .burger-lines:before {
  top: -.62em;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.burger.burger-arrow.open .burger-lines:after {
  top: .62em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

